 
import React  from 'react';
import { CartItem } from './CartItem';

function CartItemslist({cartItems,deleteItem,updateItem,lastCartItem,summary}) {
 
    

    return(
        <div className="divide-y divide-gray-300 "> 
        
        { cartItems && cartItems.length > 0 &&  
            <>  
                {cartItems.map(item => (
                <div key={item.id} className={"grid grid-cols-4 lg:grid-cols-6 w-full text-gray-800 items-center  pin-r pin-y  pt-3 px-3 pb-4 " + (lastCartItem && lastCartItem === item?.itemId ? "bg-blue-600 bg-opacity-10 " : "")} cellSpacing="0">
                   
                    <CartItem item={item} delete={deleteItem} summary={summary} updateItem={updateItem} lastCartItem={lastCartItem} />
                   
                </div>
                ))}
            </>
       
       
        
        } 
        <div />
    </div>
    )

}

export default CartItemslist;