import React, { useState, useEffect, useContext } from "react"; 
import { useForm } from "react-hook-form"; 
import {Error } from '../form/Error'
import { ChevronRightIcon } from '@heroicons/react/solid'
import { Loading } from "../layout/Loading"; 
import { AuthContext } from "../../context/context";
import axiosInstance from "../../client";
import { PassPin } from "../wizard/PassPin";

 
export default function Login(props) {
    
    const userData = props.data;  
    const [loading, setLoading] = useState(false);
 
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();  
    const [done, setDone] = useState(); 
    const [resMessage, setResponse] = useState(); 
    
    const authContext = useContext(AuthContext);

    const onSubmit = async data => {  
      setLoading(true);
      try {
        const res = await axiosInstance.post('?e=apiauth', {
          login: userData?.email,
          pass: data.pass,
        })
        if(res.data) { 
          const userResponse = {
              token: res.data.accessToken
          };
          authContext.login(userResponse.token); 
          props.updateAction({
              action: 'user',
              data: {
                email: res.data.email,
                usersDataId: res.data.usersDataId
              }
            }
          )
        }
      } catch(error) {
          setResponse(error?.response?.data?.message)
          setTimeout(() => {
            setResponse(false); 
          }, 10000);  
          setLoading(false);
      }
      setLoading(false);
    };  

    function setPass() {
      props.setModalContent({
        component: PassPin,
        params: {          
          userData: userData,
          updateAction: props.updateAction
        }
      }) 
      props.setModalIsOpen(true)
    }

    // const loginHandler = () => {
    //     //API call to server...
    //     //response from server
    //     const userResponse = {
    //         token: "abjd2323jb443jbbb"
    //     };
    //     authContext.login(userResponse.token);//setAuthContext       
    // };
    // const logoutHandler = () => {
    //     authContext.logout();
    // }; 

    useEffect(() => { 
      if(userData?.email) {
        setValue('email',userData.email)
        setDone(true)
      } else {
        setDone(false)
      }
    }
    // eslint-disable-next-line 
    , [userData]);
    
    
    return (
      <>
       


     
          <div className="flex bg-white rounded-lg overflow-hidden mx-auto max-w-sm lg:max-w-1xl">
                 
                <div className="w-full p-8 pt-0 ">
                   
                    
                    <form onSubmit={handleSubmit(onSubmit)}>
                      
                        <>
                        <div className="mt-4">
                          <label className="block text-gray-700 text-sm font-bold mb-2">Heslo</label>
                          <input autoFocus type="password"  {...register("pass", { required: true })}  
                          className={" text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none " }   />
                          {errors?.login && 
                              <Error text={'Prosím vyplňte heslo'} />                            
                          }
                        </div> 
                        {resMessage && 
                            <div className="rounded-md bg-red-50 mt-2 p-2"> 
                                <svg className="h-5 w-5 text-red-400 inline mr-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                                </svg> 
                                <span className="text-sm text-red-700">
                                    {resMessage}
                                </span>
                            </div>
                            }
                        </>
 
                    <div className="mt-3 flex justify-end">
                    
                    
                        <button   className="flex items-center   text-blue-100 h-12  px-5 py-1 focus:outline-none  space-x-4 bg-blue-600 rounded-md border border-gray-200 hover:bg-blue-700">
                          <span>Pokračovat</span>
                          <ChevronRightIcon className="w-8 " /> 
                        </button>
                    </div> 

                    </form>
                     
                </div>
            

          
    </div>
              <div className=" mt-1 max-w-sm lg:max-w-1xl p-8 mx-auto"> 
                  <div className=" items-center text-green-800 border-green-200 border   bg-green-600 bg-opacity-10 p-3 rounded-md space-y-2">
                      <div className=" font-bold text-green-600">
                      Neznáte heslo?
                      </div>
                      <div className="text-sm">Žádný problém, to rychle vyřešíme. </div>
                      <p className=" text-sm grid lg:flex">
                         
                          
                          
                      </p>
                      <div className="flex justify-end">

                            <button onClick={() => setPass()}   className="flex items-center h-12  text-green-100   px-5 py-1 focus:outline-none  space-x-4 bg-green-600 rounded-md border border-green-200 hover:bg-green-700">
                              <span>Nastavit heslo</span>
                              <ChevronRightIcon className="w-8 " /> 
                            </button> 
                      </div>
                  </div>
              </div>
      <Loading loading={loading} />
    </>
  );
}