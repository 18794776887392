
import React, { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const AddressSelect = (props) => {
    const addressData = props.addressData
    const selectedItem = props.selectedAddress
    const setItem = props.setAddress
  
    return (
        <Listbox value={selectedItem} onChange={(e) => setItem(e)}>
            {({ open }) => (
            <>
                <div className="relative mt-1">
                <Listbox.Button className={() => 
                    classNames(
                        ' border rounded-md  py-2 px-4 relative w-full  pl-3 pr-10 text-left bg-white shadow-sm cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-blue-500 focus-visible:ring-offset-blue-300 focus-visible:ring-offset-2 focus-visible:border-blue-500 ',
                        open ? 'border-blue-500 ring-2 ' : 'border-gray-300 ',
                    )
                    }>
                    <span className="block truncate">{addressData[selectedItem] ? <span className="flex justify-between"><span>{addressData[selectedItem]?.fname} {addressData[selectedItem]?.surname}, {addressData[selectedItem]?.street}, {addressData[selectedItem]?.city}</span></span> 
                    : selectedItem==='-1' ?  <span >Nová adresa</span>
                    : <span className="opacity-50">zvolte adresu</span>}</span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <ChevronDownIcon
                        className="w-5 h-5 text-gray-400"
                        aria-hidden="true"
                    />
                    </span>
                </Listbox.Button>
                <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Listbox.Options
                    static
                    className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                    >
                           <Listbox.Option
                                key={-1}
                                className={({ active }) =>
                                    `${
                                    active
                                        ? "text-blue-900 bg-blue-100"
                                        : "text-gray-900"
                                    }
                                    cursor-default select-none relative py-2 pl-10 pr-4`
                                }
                                value="-1"
                                >
                                {({ selected, active }) => (
                                    <>
                                    <span
                                        className={`${
                                        selected ? "font-medium" : "font-normal"
                                        } block truncate`}
                                    >
                                        Přidat novou adresu
                                    </span>
                                    {selected ? (
                                        <span
                                        className={`${
                                            active ? "text-blue-600" : "text-blue-600"
                                        }
                                            absolute inset-y-0 left-0 flex items-center pl-3`}
                                        >
                                        <CheckIcon
                                            className="w-5 h-5"
                                            aria-hidden="true"
                                        />
                                        </span>
                                    ) : null}
                                    </>
                                )}
                            </Listbox.Option>
                    {addressData && addressData.map((item, i) => (
                        <Listbox.Option
                        key={i}
                        className={({ active }) =>
                            `${
                            active
                                ? "text-blue-900 bg-blue-100"
                                : "text-gray-900"
                            }
                            cursor-default select-none relative py-2 pl-10 pr-4`
                        }
                        value={i}
                        >
                        {({ selected, active }) => (
                            <>
                            <span
                                className={`${
                                selected ? "font-medium" : "font-normal"
                                }  truncate grid lg:flex justify-between`}
                            >
                               <span>{item.fname} {item.surname}, {item.street}, {item.city}</span>  
                            </span>
                            {selected ? (
                                <span
                                className={`${
                                    active ? "text-blue-600" : "text-blue-600"
                                }
                                    absolute inset-y-0 left-0 flex items-center pl-3`}
                                >
                                <CheckIcon
                                    className="w-5 h-5"
                                    aria-hidden="true"
                                />
                                </span>
                            ) : null}
                            </>
                        )}
                        </Listbox.Option>
                    ))}
                    </Listbox.Options>
                </Transition>
                </div>
            </>
            )}
        </Listbox>
    )
}