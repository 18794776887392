import React, { useCallback, useEffect, useState } from "react";     
import { useForm } from "react-hook-form";   
import { ChevronRightIcon, XIcon, MinusIcon, PlusIcon } from "@heroicons/react/outline"; 

export const CartItem = ({item, deleteItem,updateItem,summary}) => {

    
    const { register, handleSubmit, getValues, setValue, formState: { errors }  } = useForm({
        mode: "onBlur"
    });  
    
    const [count, setCount] = useState(parseInt(item.count)); 

      const onSubmit = async data => { 
          setCount(data.count)
      };
      
      
    useEffect(() => {
        updateItem(item.id,count) 
        setValue('count',count)
    },[count]);   

      const debounce = (func, debounceTimeout) => {
        let timeout;
        return function executedFunction(...args) {
            const delayedFunction = () => {
                clearTimeout(timeout);
                func(...args);
            };
            clearTimeout(timeout);
            timeout = setTimeout(delayedFunction, debounceTimeout);
        };
    };

        // eslint-disable-next-line
        const debouncedSubmit = useCallback(
            debounce(name => { 
                setCount(getValues('count')); 
            }, 700),
            []
        );

        function change() {
            debouncedSubmit();
        }

    return (
        <>

        
            <div className={"flex space-x-2 lg:space-x-0 justify-between " + (item.type !== 'cart_promo' && item.type !== 'eorder' ? summary ? "col-span-3 lg:col-span-5 " : "col-span-2 lg:col-span-4" : 
                    summary ? "col-span-2 lg:col-span-5 " : "col-span-2 lg:col-span-5")} >
                        <div>
                            <p className="font-medium ">{item.name}</p>

                            {item.pack_items && 
                            <ul className="my-2">
                            {item.pack_items.map((item,i) => (
                                <li key={i} className="text-sm flex">
                                    <ChevronRightIcon className="w-5 text-gray-500 mr-1" />
                                    {item.mod_kurzy_nazev}
                                </li>
                            ))}
                            </ul>
                            }
                            <p className="text-xs text-gray-500">{item.note}</p>
                        </div>
                        
                        
                     
                    </div> 
                        {item.type !== 'cart_promo' && item.type !== 'eorder' &&
                            <>
                    <div className="col-span-2 lg:col-span-1 flex flex-row items-center lg:justify-center text-gray-400 ">
 
                      
                    
                            <button type="button" onClick={() => setCount(parseInt(item.count)-1)}  className="bg-gray-200 text-gray-600  h-8 flex items-center rounded-l-md p-2 px-1 hover:bg-gray-700 hover:text-white">
                                <MinusIcon  className="w-4 h-4 cursor-pointer" />
                            </button> 
                                <form onSubmit={handleSubmit(onSubmit)}  > 
                                    <input type="number" {...register("count",{ required: true })} defaultValue={count} onInput={() => change()} min="1" className="shadow-sm focus:ring-sky-500 focus:border-sky-500 text-gray-700 block text-lg w-16 h-8 pr-0 text-center   border-gray-300 "/>
                                </form>  
                            <button type="button" onClick={() => setCount(parseInt(item.count)+1)} className="bg-gray-200 text-gray-600  h-8 flex items-center rounded-r-md p-2 px-1 hover:bg-gray-700 hover:text-white">
                                <PlusIcon className="w-4 h-4  " />
                            </button>
                              
                    </div> 
                            </>
                        }
                  
                        <div className="col-span-2 lg:col-span-1 flex justify-end items-center space-x-5 ">
                            <span className=" font-medium">
                                {parseFloat(item.price).toLocaleString('cs')} Kč
                            </span>
                            {!summary && 
                            <div className=" justify-end items-center lg:flex" >
                                <button type="button" onClick={() => deleteItem(item.id)} className="text-gray-400 hover:text-gray-900 focus:outline-none py-3">
                                    <XIcon className="w-6 text-gray-500"  />
                                </button>
                            </div>
                             }
                        </div> 
        </>
    )
}