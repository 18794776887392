
import React, { useState, useEffect, useContext } from "react"; 
import { useForm } from "react-hook-form";   
import { AuthContext } from "../../context/context";
import {  
  ChevronRightIcon, 
} from '@heroicons/react/solid' 
import axiosInstance from "../../client/index.js";
import { Address } from "./Address.js";
import Login from "./Login.js";
import Email from "./Email.js";
import { Loading } from "../layout/Loading.js";
   
 
export default function Userdata(props) {
   const authContext = useContext(AuthContext);
     

    const [loading, setLoading] = useState(false);    
    const [action, setAction] = useState();
    const [addressData, setAddressData] = useState();

    function updateAction(data) {
      if (data) {  
        localStorage.setItem('userDataStatus', JSON.stringify(data)); 
        setAction(data);
      } else {
        localStorage.removeItem('userDataStatus'); 
        setAction(false);
      }
    } 

    const { register, handleSubmit, setValue, formState: { errors }  } = useForm({
      mode: "onBlur"
    });  
 
    const onSubmit = async data => { 
      setLoading(true);
      try {
        const res = await axiosInstance.post('users_data/signUp/', 
        {
          data: data,
          email: action?.data?.email,
          addressId: data.addressId,
          usersDataId: action?.data?.usersDataId,
          cartId: props.cartId
        })
        if(res.data) { 
          updateAction({
              action: action?.action,
              data: {
                email: action?.data?.email,
                usersDataId: res.data.usersDataId,
                addressId: res.data.addressId,
                data: res.data
              }
            }
          )  
        }
      } catch(err) {
          console.log(err);
      }
      setLoading(false); 
    };  

    async function getAddress() {
      if(!addressData) {
        try {
          const res = await axiosInstance.post('users_data/getAddress/', 
          { 
            usersDataId: action?.data?.usersDataId, 
          })
          if(res.data) { 
            setAddressData(res.data)
          }
        } catch(err) {
            console.log(err);
        }
      }
    }

    useEffect(() => {   
      setAction(JSON.parse(localStorage.getItem('userDataStatus'))); 
    }
    // eslint-disable-next-line 
    , [authContext]); 

    // useEffect(() => {   
    //   authContext.isLoggedIn ? getAddress() : setAddressData(null)
    // }
    // // eslint-disable-next-line 
    // , [authContext,action]); 
      
    return (
       
            <> 
            <div className={"" + (action?.action==='login' || !action ? "" : "hidden" )}>
              <Email data={action?.data} updateAction={updateAction} />
            </div>

            {action?.action==='login' && 
              <Login {...props} data={action?.data} updateAction={updateAction} />
            }

            {action?.action==='register' || action?.action==='user' ?  
             
             <form key={1} onSubmit={handleSubmit(onSubmit)}  className={"space-y-8  py-2"  } >

                    
                    <Address data={action?.data} register={register} errors={errors} setValue={setValue} updateAction={updateAction} addressData={addressData}  />

                    <div className="sm:col-span-2 mt-3 flex justify-end">   
                      <button   className="flex items-center   text-blue-100 h-12  px-5 py-1 focus:outline-none  space-x-4 bg-blue-600 rounded-md border border-gray-200 hover:bg-blue-700">
                        <span>Pokračovat</span>
                        <ChevronRightIcon className="w-8 " /> 
                      </button> 
                    </div>
              
                  
              </form>  
              : ""
          }
          <Loading loading={loading} />
        </>
       
    )
   }
 
